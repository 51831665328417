import React, { useState } from "react";
import { IResourceComponentsProps } from "@pankod/refine-core";
import {
  Edit,
  Form,
  Input,
  Select,
  useForm,
  useSelect,
  Upload,
  Radio
} from "@pankod/refine-antd";
import {
  useStrapiUpload,
  getValueProps,
  mediaUploadMapper
} from "@pankod/refine-strapi-v4";

import ReactMarkdown from "react-markdown";
import ReactMde from "react-mde";
import "react-mde/lib/styles/css/react-mde-all.css";

import { TOKEN_KEY, API_URL } from "../../constants";
import { ICategory, IPost } from "interfaces";

export const PostEdit: React.FC<IResourceComponentsProps> = () => {
  const [selectedTab, setSelectedTab] = useState<"write" | "preview">("write");

  const { formProps, saveButtonProps, queryResult } = useForm<IPost>({
    metaData: { populate: ["category", "cover"] }
  });

  const { selectProps } = useSelect<ICategory>({
    resource: "categories",
    defaultValue: queryResult?.data?.data?.category?.data?.id,
    metaData: { locale: queryResult?.data?.data.locale }
  });

  const { ...uploadProps } = useStrapiUpload({
    maxCount: 1
  });

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form
        {...formProps}
        layout="vertical"
        onFinish={(values: any) => {
          return formProps.onFinish?.(mediaUploadMapper(values));
        }}
      >
        <Form.Item label="Locale" name="locale">
          <Radio.Group disabled>
            <Radio.Button value="en">English</Radio.Button>
            <Radio.Button value="zh-Hans">中文</Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          wrapperCol={{ span: 14 }}
          label="Title"
          name="title"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          wrapperCol={{ span: 8 }}
          label="Category"
          name={["category", "id"]}
          rules={[
            {
              required: true
            }
          ]}
        >
          <Select {...selectProps} />
        </Form.Item>
        <Form.Item
          label="Content"
          name="content"
          rules={[
            {
              required: true
            }
          ]}
        >
          <ReactMde
            selectedTab={selectedTab}
            onTabChange={setSelectedTab}
            generateMarkdownPreview={(markdown) =>
              Promise.resolve(<ReactMarkdown>{markdown}</ReactMarkdown>)
            }
          />
        </Form.Item>
        <Form.Item label="Cover">
          <Form.Item
            name="cover"
            valuePropName="fileList"
            getValueProps={(data) => getValueProps(data, API_URL)}
            noStyle
          >
            <Upload.Dragger
              name="files"
              action={`${API_URL}/api/upload`}
              headers={{
                Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`
              }}
              listType="picture"
              multiple
              {...uploadProps}
            >
              <p className="ant-upload-text">Drag & drop a file in this area</p>
            </Upload.Dragger>
          </Form.Item>
        </Form.Item>
      </Form>
    </Edit>
  );
};
